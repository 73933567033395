<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-card>
        <v-toolbar
          dense
          card
        >
          <v-select
            v-model="filter"
            :items="['All','OneXOX','XOX']"
            label="Filter by"
            clearable
          />
        </v-toolbar>
        <v-date-picker
          v-model="date"
          full-width
          :landscape="$vuetify.breakpoint.smAndUp"
          :max="today"
          class="mt-3"
          header-color="indigo lighten-1"
          color="orange"
        />
      </v-card>
      <v-data-table
        :headers="headers"
        :items="activations"
        item-key="state"
        hide-actions
        class="mt-3 elevation-1"
      >
        <template #items="props">
          <tr>
            <td class="text-xs-center">
              {{ props.item.state }}
            </td>
            <td class="text-xs-center info">
              {{ props.item.activation }}
            </td>
            <td class="text-xs-center info">
              {{ props.item.recruitment }}
            </td>
          </tr>
        </template>
        <template slot="footer">
          <tr>
            <td class="text-xs-right">
              Total:
            </td>
            <td class="text-xs-center info">
              {{ totalActivations }}
            </td>
            <td class="text-xs-center info">
              {{ totalRecruitments }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-toolbar
        class="mt-3"
        flat
      >
        <v-toolbar-title>Daily recruitments under Master Dealers</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-spacer />
      </v-toolbar>
      <v-data-table
        :headers="masterHeaders"
        :items="masters"
        item-key="dealer"
        hide-actions
        disable-initial-sort
        class="elevation-1"
      >
        <template #items="props">
          <tr>
            <td class="text-xs-left">
              {{ props.item.dealer }}
            </td>
            <td class="text-xs-left">
              {{ props.item.name }}
            </td>
            <td class="text-xs-center info">
              {{ props.item.recruitment }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </panel>
  </v-scale-transition>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'
export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      date: DateTime.local().setZone('Asia/Kuala_Lumpur').minus({ days: 1 }).toFormat('yyyy-LL-dd'),
      today: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Daily Excerpt', disabled: false, to: '/management/daily_view',
        },
        {
          text: 'Activations & Recruitment by State', disabled: true,
        },
      ],
      filter: 'All',
      activations: [],
      masters: [],
      headers: [
        {
          text: 'State',
          align: 'center',
          sortable: true,
          value: 'state',
        },
        {
          text: 'Activations',
          align: 'center',
          sortable: true,
          value: 'activation',
        },
        {
          text: 'Recruitments',
          align: 'center',
          sortable: true,
          value: 'recruitment',
        },
      ],
      masterHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Recruitments',
          align: 'center',
          sortable: true,
          value: 'recruitment',
        },
      ],
      title: 'Activations & Recruitments by State',
      totalActivations: 0,
      totalRecruitments: 0,
    }
  },
  created: function () {
    this.getActivationsByState(this.date)
  },
  computed: {
    // minDate: function () {
    //   const offset = 5
    //   const parts = this.today.split('-')
    //   if (Number(parts[2]) >= offset) {
    //     return parts[0] + '-' + parts[1] + '-01'
    //   } else {
    //     return DateTime.local().setZone('Asia/Kuala_Lumpur').minus({ days: offset }).toFormat('yyyy-LL-01')
    //   }
    // },
  },
  watch: {
    date: function (val) {
      this.getActivationsByState(val)
    },
    filter: function (val) {
      this.getActivationsByState(this.date)
    },
  },
  methods: {
    getActivationsByState (date) {
      const localParams = {
        date,
      }
      if (this.filter) {
        Object.assign(localParams, { claimer: this.filter })
      }
      const params = createGetParams(localParams)
      this.$rest.get('getActivationsByState.php', params)
        .then(function (response) {
          this.activations = response.data.items
          this.totalActivations = response.data.totalActivations
          this.totalRecruitments = response.data.totalRecruitments
          this.masters = response.data.master
          // } catch (error) {
          //   console.log(error)
          // }
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
